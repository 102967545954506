const generateDefaultDomainsForInstance = (instance: string): string[] => {
  return [
    `${instance}.whitelabel.imxplatform.de`, // (default) production environment
    `main.${instance}.whitelabel.imxplatform.de`, // test environment
    `${instance}-whitelabel.infomax.dev`, // development environment
  ];
};

const INSTANCE_TO_DOMAINS: Record<string, string[]> = {
  demo: [
    'whitelabel.imxplatform.de', // production environment
    'storybook-whitelabel.imxplatform.de', // production environment
    'main.whitelabel.imxplatform.de', // test environment
    'main.storybook-whitelabel.imxplatform.de', // test environment
    'whitelabel.infomax.dev', // development environment
    'widget-whitelabel.infomax.dev', // development environment
    'storybook-whitelabel.infomax.dev', // development environment
  ],
  fwtm: [
    ...generateDefaultDomainsForInstance('fwtm'),
    // add custom domains here
  ],
  hht: [...generateDefaultDomainsForInstance('hht')],
  rnd: [...generateDefaultDomainsForInstance('rnd')],
  tash: [...generateDefaultDomainsForInstance('tash')],
};

export const DOMAIN_TO_INSTANCE_MAP: Map<string, string> = new Map(
  Object.keys(INSTANCE_TO_DOMAINS).flatMap((instance) =>
    INSTANCE_TO_DOMAINS[instance].map((domain) => [domain, instance])
  )
);
