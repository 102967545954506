import urql, {
  cacheExchange,
  fetchExchange,
  type SSRData,
  ssrExchange,
} from '@urql/vue';

const ssrKey = '__URQL_DATA__';

export default defineNuxtPlugin((nuxtApp) => {
  console.debug('IMXWHL: plugin urql');

  const { vueApp } = nuxtApp;

  const ssr = ssrExchange({
    isClient: import.meta.client,
  });

  // when app is created in browser, restore SSR state from nuxt payload
  if (import.meta.client) {
    nuxtApp.hook('app:created', () => {
      ssr.restoreData(nuxtApp.payload[ssrKey] as SSRData);
    });
  }

  // when app has rendered in server, send SSR state to client
  if (import.meta.server) {
    nuxtApp.hook('app:rendered', () => {
      nuxtApp.payload[ssrKey] = ssr.extractData();
    });
  }

  const instanceConfig = useWhlInstanceConfig();
  vueApp.use(urql, {
    url: instanceConfig.value.graphqlEndpoint,
    exchanges: [
      cacheExchange,
      ssr, // add `ssr` in front of the `fetchExchange`
      fetchExchange,
    ],
    fetchOptions: () => {
      const token = instanceConfig.value.graphqlBearerToken;
      return {
        headers: { authorization: token ? `Bearer ${token}` : '' },
      };
    },
  });
});
