import { MediaTypeEnum } from '../gql/schema';
import {
  ContactType,
  type BaseInstanceConfig,
} from '../models/BaseInstanceConfig';
import { BaseTourDifficulty } from '../models/BaseTourDifficulty';

enum FWTM_TOUR_CATEGORIES {
  AKTIVTOUREN = 3,
  MOTORISIERTE_TOUREN = 4,
  PISTE = 5,
  RADTOUREN = 6,
  RUNDGANG = 7,
  WANDERUNG = 8,
  WASSERTOUREN = 9,
  WINTERTOUREN = 10,

  // Aktivtouren
  INLINE_SKATING_TOUR = 11,
  KLETTERTOUR = 12,
  NORDIC_WALKING_TOUR = 13,
  REITTOUR = 14,

  // Motorisierte Touren
  FERIENSTRASSE = 17,
  MOTORRADTOUR = 15,
  OFFROADTOUR = 18,
  WOHNMOBILTOUR = 16,

  // Piste
  BUCKELPISTE = 20,
  SKIPISTE = 19,
  SKIROUTE = 22,
  UEBUNGHANG = 21,
  ZIEHWEG = 23,

  // Radtouren
  GRAVELTOUR = 25,
  MTBTOUR = 26,
  RADFERNWEG = 24,
  RADTOUR = 27,
  RENNRADTOUR = 28,

  // Rundgang
  SPAZIERGANG = 29,

  // Wanderung
  ALPINTOUR = 35,
  BERGTOUR = 37,
  FERNWANDERWEG = 32,
  KLETTERSTEG = 31,
  LEHRPFAD = 36,
  PILGERWEG = 33,

  // Wassertouren
  BOOTSTOUR = 39,
  KAJAKTOUR = 41,
  SEGELTOUR = 40,

  // Wintertouren
  LOIPE = 42,
  RODELABFAHRT = 46,
  SCHNEESCHUHTOUR = 43,
  SKITOUR = 44,
  WINTERWANDERWEG = 45,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  defaultWidgetId: 1,
  defaults: {
    hero: {
      filter: { mediaType: [MediaTypeEnum.Image], imageType: { eq: 4 } },
      fallback: [{ mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } }],
    },
    gallery: {
      filter: {
        or: [
          { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
          { mediaType: [MediaTypeEnum.Video] },
        ],
      },
    },
    list: {
      filter: {
        mediaType: [MediaTypeEnum.Image],
        imageType: { oneOf: [4, 1] },
      },
    },
    article: {
      contact: ContactType.CONTACT,
    },
  },
  enabledLanguages: ['de', 'en'],
  footerContact: {
    name: 'Freiburg Wirtschaft Touristik und Messe GmbH & Co. KG',
    street: 'Neuer Messplatz 3',
    zipcode: '79108',
    city: 'Freiburg',
    email: 'info@visit.freiburg.de',
  },
  footerLogo: '/assets/images/instances/fwtm/logo.white.svg',
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvZnJlaWJ1cmcuaW14cGxhdGZvcm0uZGVcL29hdXRoIiwic3ViIjoiODkiLCJsbmFtZSI6IndzLndoaXRlbGFiZWwtd2lkZ2V0cyJ9.gg4QFEiSaiBDAUj0bmiAvMgJ__Fm7zWG6TJm3kxROuxOcttkuHDCmxy97vd6mFh6CJLH0dkmnYNpDoG3T6YdzJSbb1AF04MWBOi9siGtwwvijeXID4Ao4Jk-3RwRRkLRz1AmxaQRlXKT4oOMI_TJEnFtaLMywG7DW4tiycNkKzFvwtovl7w3UHBRwd63ZtaTsUCs0opH9APCkb8SgS48ibL-eBbBC4I_OxA8oVW8NnbT90JqkK3Bz05cuUfbYXfNXwRJpOCKuIqapAFdsJtpxj7qtBNTd1uf-Bav2ipwLXdo8yvJYswGR4kgLhBkZmnUxc4eY-SzsBrI_u_ZMGXKZA',
  graphqlEndpoint: `https://content-delivery.imxplatform.de/fwtm/imxplatform`,
  headerLogo: '/assets/images/instances/fwtm/logo.colored.svg',
  map: {
    defaultCoords: {
      // freiburg
      latitude: 47.996014049603474,
      longitude: 7.8495559643174255,
    },
  },
  poi: {
    openingHours: {
      hideDateRangeInfo: true,
    },
  },
  tour: {
    categoryIcons: {
      [FWTM_TOUR_CATEGORIES.AKTIVTOUREN]: 'ion:active',
      [FWTM_TOUR_CATEGORIES.MOTORISIERTE_TOUREN]: 'ph:motorcycle-fill',
      [FWTM_TOUR_CATEGORIES.PISTE]: 'lets-icons:winter',
      [FWTM_TOUR_CATEGORIES.RADTOUREN]: 'ion:bicycle',
      [FWTM_TOUR_CATEGORIES.RUNDGANG]: 'icon-park-solid:turn-around',
      [FWTM_TOUR_CATEGORIES.WANDERUNG]: 'material-symbols:hiking',
      [FWTM_TOUR_CATEGORIES.WASSERTOUREN]: 'ion:boat-outline',
      [FWTM_TOUR_CATEGORIES.WINTERTOUREN]: 'lets-icons:winter',
    },
    difficultyMapping: {
      1: BaseTourDifficulty.EASY,
      2: BaseTourDifficulty.EASY,
      3: BaseTourDifficulty.MEDIUM,
      4: BaseTourDifficulty.HARD,
      5: BaseTourDifficulty.HARD,
    },
  },
};
