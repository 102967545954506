import {
  type BaseInstanceConfig,
  ContactType,
  RoutingService,
} from '../models/BaseInstanceConfig';

enum TASH_TOUR_CATEGORIES {
  AUF_DEM_WASSER = 20,
  FUER_SKATER = 30,
  IM_WINTER = 28,
  MIT_DEM_FAHRRAD = 1,
  MIT_TIEREN = 12,
  MOTORISIERT = 16,
  ZU_FUSS = 3,

  // Auf dem Wasser
  KAJAKTOUR = 26,
  KANUTOUR = 21,
  MOTORBOOT = 23,
  SCHWIMMEN = 27,
  SEGELN = 22,

  // Für Skater
  AUF_INLINESKATES = 31,
  AUF_SKATEBOARD = 32,

  // Im Winter
  SKILANGLAUF = 29,

  // Mit dem Fahrrad
  HANDBIKE = 33,
  MOUNTAINBIKE = 2,
  RENNRAD = 9,
  FAHRRAD_TAGESTOUR = 11,

  // Mit Tieren
  KUTSCHE = 14,
  PACKTIER = 15,
  REITEN = 13,

  // Motorisiert
  MOTORRAD = 18,
  QUAD = 19,
  SUV = 17,

  // Zu Fuß
  FERNWANDERWEGE = 4,
  KURZWANDERWEGE = 7,
  NATURLEHRPFAD = 6,
  STADTRUNDGANG = 24,
  FUSS_TAGESTOUR = 8,
  WALKING = 5,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  defaultWidgetId: 1,
  defaults: {
    article: {
      contact: ContactType.CONTACT,
    },
  },
  enabledLanguages: ['de'],
  footerContact: {
    name: 'Tourismus-Agentur Schleswig-Holstein GmbH',
    street: 'Wall 55',
    zipcode: '24103',
    city: 'Kiel',
    email: 'info@sht.de',
  },
  footerLogo: '/assets/images/instances/tash/logo.white.svg',
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvdGFzaC5pbXhwbGF0Zm9ybS5kZVwvZGF0YVwvaW14cGxhdGZvcm1qXC9vYXV0aCIsInN1YiI6IjY0MiIsImxuYW1lIjoid3Mud2hpdGVsYWJlbC13aWRnZXRzIn0.D-56_7KUsFuEC33le63wWQTcwoWGK_7ydb1w_pZ4_5WbyFzjreGtWa9SxkolbL_jftNpT2TEijlggRKIA1_g-zQCpukfNaWo9cWRo3Yb_e1CMn8VyaCV-o0aUvZ4fhAHN5Bg-Hn4zlMZ8kE1i-tfBRZRRQRbHgV8f3g5QqisminIKDtvvwt71I-s2E6W6q4aKiWdIPo4ioGQGjJcoXA6J37yZIUW-2fiIvAcbr4RtVxIV85PhxVolcaW47yWNzSI5xz0uUiZ2-6YjJtfsLbvZym3-cdwzFmYxHqUEDPXjdqHwrlQ7y9HkbhnHGXPNdpF70_JPVZ3bOZDIkwoZZl0Mg',
  graphqlEndpoint: `https://content-delivery.imxplatform.de/tash/imxplatform`,
  headerLogo: '/assets/images/instances/tash/logo.colored.svg',
  map: {
    defaultCoords: {
      // kiel
      latitude: 54.32231,
      longitude: 10.14317,
    },
    defaultZoom: 10,
    weatherControls: true,
  },
  routing: {
    publicTransportRoutingService: RoutingService.NAHSH,
    carRoutingService: RoutingService.GOOGLE_MAPS,
    publicTransportStationContactPurposeId: 4,
    publicTransportBusProductlineIds: [1441, 1443],
    publicTransportTrainProductlineIds: [1442],
  },
  tour: {
    categoryIcons: {
      [TASH_TOUR_CATEGORIES.ZU_FUSS]: 'ion:active',
      [TASH_TOUR_CATEGORIES.MOTORISIERT]: 'ph:motorcycle-fill',
      [TASH_TOUR_CATEGORIES.MIT_DEM_FAHRRAD]: 'ion:bicycle',
      [TASH_TOUR_CATEGORIES.STADTRUNDGANG]: 'icon-park-solid:turn-around',
      [TASH_TOUR_CATEGORIES.FERNWANDERWEGE]: 'material-symbols:hiking',
      [TASH_TOUR_CATEGORIES.KURZWANDERWEGE]: 'material-symbols:hiking',
      [TASH_TOUR_CATEGORIES.NATURLEHRPFAD]: 'material-symbols:hiking',
      [TASH_TOUR_CATEGORIES.AUF_DEM_WASSER]: 'ion:boat-outline',
      [TASH_TOUR_CATEGORIES.IM_WINTER]: 'lets-icons:winter',
      [TASH_TOUR_CATEGORIES.REITEN]: 'mdi:horse',
    },
  },
};
